<div class="flex flex-row items-stretch flex-no-wrap">
  <header class="w-[15%]">
    <nav
      class="flex flex-col justify-between w-full min-h-[100vh] bg-white border-r-[1px] sticky top-0"
    >
      <div class="flex flex-col items-center justify-start py-2 space-y-4">
        <a
          class="flex items-center text-neutral-900 hover:text-neutral-900 focus:text-neutral-900 dark:text-neutral-200 dark:hover:text-neutral-400 dark:focus:text-neutral-400"
          href="#"
        >
          <img
            src="./../assets/logo-juboo.png"
            style="width: 120px"
            alt="TE Logo"
            loading="lazy"
          />
        </a>
        <hr class="my-2"/>
        <ul class="list-style-none w-full px-4">
          <li class="cursor-pointer">
            <a routerLink="/backoffice/requestlist"><i class="fa-solid fa-list me-2"></i> {{ 'BACKOFFICE.NAV.RECONCILIATION' | translate }}</a>
          </li>
          <hr class="bg-black w-[100%] my-3" />
          <li class="cursor-pointer" (click)="logoutService.logout()">
            <a><i class="fa-solid fa-power-off me-2"></i> {{ 'BACKOFFICE.NAV.LOGOUT' | translate }}</a>
          </li>
        </ul>
      </div>
      <div class="flex flex-row items-end justify-center py-4 w-full">
        <img
          class="h-10 w-10 rounded-full shadow-md object-cover me-2"
          src="{{ imageUrl }}"
          alt="logo"
        />
        <h5 class="text-lg font-medium text-wrap">
          {{
            this.currentUser.customerName
              ? this.currentUser.customerName
              : this.currentUser.firstName + ' ' + this.currentUser.lastName
          }}
        </h5>
      </div>
    </nav>
  </header>

  <main class="w-[85%] bg-neutral-100">
    <div class="px-8 py-4">
      <router-outlet></router-outlet>
    </div>
  </main>
</div>
