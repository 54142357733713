import { Routes } from '@angular/router';
import { CreateRequestComponent } from './backoffice/create-request/create-request.component';
import { RequestDetailComponent } from './backoffice/request-detail/request-detail.component';
import { RequestListComponent } from './backoffice/request-list/request-list.component';
import { LoginComponent } from './login/login.component';
import { BackofficeComponent } from './backoffice/backoffice.component';
import { authGuard } from './core/guard/auth/auth.guard';
import { agentGuard } from './core/guard/role/role.guard';
import { ForgottenpasswordComponent } from './forgottenpassword/forgottenpassword.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';

export const routes: Routes = [
  { path: '', redirectTo: '/backoffice/requestlist', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: 'forgot-password', component: ForgottenpasswordComponent },
  { path: 'account/reset/finish', component: ResetPasswordComponent },
  {
    path: 'backoffice',
    component: BackofficeComponent,
    canActivate: [authGuard],
    children: [
      { path: 'createrequest', canActivate: [agentGuard], component: CreateRequestComponent },
      { path: 'requestlist', component: RequestListComponent },
      { path: 'requestdetail/:id', component: RequestDetailComponent },
    ],
  },
];
