import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';
import 'moment/locale/fr';

@Pipe({
	name: 'friendlyDate',
	standalone: true
})
export class FriendlyDatePipe implements PipeTransform {

	transform(date: any, option?: string): string {
		const browserLanguage = navigator.language || (navigator as any).userLanguage;
		const locale = browserLanguage ? browserLanguage.split('-')[0] : 'fr';

		if (option === 'text') {
			const options:any = { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric' };
			return (new Date(date)).toLocaleDateString(browserLanguage, options);
		}

		return moment(date).locale(locale).calendar(null, {
			sameDay: `[${this.getTranslatedToday(locale)}] HH:mm`,
			lastDay: `[${this.getTranslatedYesterday(locale)}] HH:mm`,
			lastWeek: `dddd  [${this.getTranslateLastWeek(locale)}] HH:mm`,
			sameElse: `dddd, DD MMM 2024   [${this.getTranslateSameElse(locale)}] HH:m`

		});
	}

	private getTranslatedToday(locale: string): string {
		const translations: Record<string, string> = {
		  'fr': 'Aujourd\'hui à',
		  'en': 'Today at',
		};
		return translations[locale.split('_')[0]] || 'Aujourd\'hui';
	}

	private getTranslatedYesterday(locale: string): string {
		const translations: Record<string, string> = {
		  'fr': 'Hier à',
		  'en': 'Yesterday at',
		};
		return translations[locale.split('_')[0]] || 'Hier';
	}

	private getTranslateLastWeek(locale: string): string {
		const translations: Record<string, string> = {
		  'fr': 'dernier à',
		  'en': 'last at',
		};
		return translations[locale.split('_')[0]] || 'dernier à';
	}

	private getTranslateSameElse(locale: string): string {
		const translations: Record<string, string> = {
		  'fr': 'à',
		  'en': 'at',
		};
		return translations[locale.split('_')[0]] || 'à';
	}

}
