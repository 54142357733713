import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment.dev';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(private router: Router, private http: HttpClient) { }
  isLogged = () => {
    const isAuthenticated = localStorage.getItem('authenticationToken');
    if (isAuthenticated) return true;
    else return false;
  }
  logout = () => {
    localStorage.clear();
    this.router.navigateByUrl('/');
  };

  forgottentPassword = (payload: any) => {
    return this.http.post(`${environment.apiUrl}/account/reset-password/init`, payload);
  }

  resetForgottentPassword = (payload: any) => {
    return this.http.post(`${environment.apiUrl}/account/reset-password/finish`, payload);
  }
}
