
<div
  class="w-full flex min-h-[100vh] flex-col justify-center items-center bg-brown-500"
  id="login-bg">
  <div
    class="flex w-[350px] bg-white flex-col justify-center px-6 py-12 lg:px-8 rounded-xl border border-radius"
  >
    <div class="sm:mx-auto sm:w-full sm:max-w-sm">
      <div class="my-4 flex justify-center items-center">
        <img
          src="./../assets/logo.png"
          class="pl-0 pr-4"
          style="width: 80%"
          alt="Logo Juboo"
          loading="lazy"
        />
      </div>
      <h2 class="text-center">{{ 'FORGOT_PASSWORD.TITLE' | translate }}</h2>
    </div>

    <div class="sm:mx-auto sm:w-full sm:max-w-sm">
      <form
        class="space-y-6"
        [formGroup]="forgotPasswordForm"
        (submit)="onSubmit($event)"
      >
        <div *ngIf="successMessage" class="bg-green-200 text-green-700 mt-3 p-5 justify-center items-center rounded mb-0">
          {{ successMessage }}
        </div>
        <div *ngIf="showButtonConfirme">
          <div>
              <label for="username" class="label">{{ 'FORGOT_PASSWORD.EMAIL_LABEL' | translate }} </label>
              <div class="mt-2">
                  <input
                  id="username"
                  name="username"
                  class="input"
                  formControlName="username"
                  placeholder="exemple@exemple.com"
                  />
              </div>
          </div>
          <div class="w-full flex justify-center items-center mb-4 mt-4">
              <button class="button">{{ 'FORGOT_PASSWORD.CONFIRM_BUTTON' | translate }}</button>
          </div>
        </div>
        <div class="w-full flex justify-center items-center mb-4">
            <a [routerLink]="['/login']" class="text-brown-500 hover:underline text-center">{{ 'FORGOT_PASSWORD.BACK_TO_LOGIN' | translate }}</a>
        </div>
      </form>
    </div>
  </div>
</div>
