import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'readableStatus',
  standalone: true
})
export class ReadableStatusPipe implements PipeTransform {

	constructor(private translate: TranslateService) {}
	

	transform(value: string): string {
		switch(value) {
			case 'NEW':
				return this.translate.instant('READABLE_STATUS.NEW');
			case 'DONE':
				return this.translate.instant('READABLE_STATUS.DONE');
			case 'TRANSFORMATION_ERROR':
				return this.translate.instant('READABLE_STATUS.TRANSFORMATION_ERROR');
			case 'RECONCILIATION_ERROR':
				return this.translate.instant('READABLE_STATUS.RECONCILIATION_ERROR');
			default:
				return this.translate.instant('READABLE_STATUS.IN_PROGRESS');
		}
  }
}
