import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'friendlyName',
  standalone: true
})
export class FriendlyNamePipe implements PipeTransform {

  transform(name: String, ...args: number[]): unknown {
    return name.length > args[0] ? name.slice(0, args[0]) + ' ...' : name;
  }

}
