import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appRequestStatus]',
  standalone: true
})
export class RequestStatusDirective implements OnInit{

  @Input('appRequestStatus') requestStatus!: string;

  constructor(private el: ElementRef, private renderer: Renderer2) { }

  ngOnInit(): void {
    this.updateStatusColor();
  }

  private updateStatusColor(): void {
    if (this.requestStatus === 'DONE') {
      this.renderer.setStyle(this.el.nativeElement, 'color', '#65a30d');
      this.renderer.setStyle(this.el.nativeElement, 'backgroundColor', '#f7fee7');
    } 
    else if (this.requestStatus === 'NEW'){
      this.renderer.setStyle(this.el.nativeElement, 'color', '#1d4ed8');
      this.renderer.setStyle(this.el.nativeElement, 'backgroundColor', '#dbeafe');
    }
    else {
      this.renderer.setStyle(this.el.nativeElement, 'color', '#ef4444');
      this.renderer.setStyle(this.el.nativeElement, 'backgroundColor', '#fef2f2');
    }
  }

}
