import { CommonModule } from '@angular/common';
import {Component, computed, signal} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { environment } from './../../../environments/environment';
import {
  HttpClient,
  HttpClientModule
} from '@angular/common/http';
import { Router } from '@angular/router';
import { TranslateDirective, TranslatePipe, TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-create-request',
  standalone: true,
  templateUrl: './create-request.component.html',
  imports: [ReactiveFormsModule, CommonModule, RouterModule, HttpClientModule, TranslatePipe, TranslateDirective],
})
export class CreateRequestComponent {
  private endpointPrefix = environment.apiUrl;
  requestForm!: FormGroup;
  loading: Boolean = false;
  errorMsg: string = '';
  fileData: File[] = [];
  sourcetypes = signal<any[]>([]);
  externalSourcetypes = computed(() => {
    return this.sourcetypes().filter((sourceType: any) => sourceType.sourceTypeMaster != null);
  });
  selectedSourcetype!: any;
  customers: any[] = [];
  acceptedFileTypes: { [key: number]: string } = { 0: '', 1: '' };
  formatToExtension: { [key: string]: string } = {
    PDF: '.pdf',
    XLSX: '.xlsx',
    XLS: '.xls',
    CSV: '.csv',
    ODS: '.ods',
    TXT: '.txt',
    JRN: '.jrn',
  };

  constructor(
    private fb: FormBuilder,
    private http: HttpClient,
    private router: Router,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.requestForm = this.fb.group({
      name: ['', Validators.required],
      typeSource1: ['', Validators.required],
      source1: ['', Validators.required],
      typeSource2: ['', Validators.required],
      source2: ['', Validators.required]
    });
    if (this.isAdmin)
      this.getCustomers();
    else this.getSourceTypes();
  }

  getSourceTypes() {
    this.http.get(this.endpointPrefix + '/source-types')
      .subscribe(
        (res: any) => {
          this.errorMsg = '';
          this.loading = false;
          this.sourcetypes.set(res);
        },
        (err: any) => {
          this.errorMsg = err;
          this.loading = false;
        }
      )
  }

  getCustomers() {
    this.http.get(this.endpointPrefix + '/customers')
      .subscribe(
        (res: any) => {
          this.errorMsg = '';
          this.loading = false;
          this.customers = res;
        },
        (err: any) => {
          this.errorMsg = err;
          this.loading = false;
        }
      )
  }

  selectCustomer(e: any) {
    this.sourcetypes = this.customers.find((elt: any) => elt.id.toString() === e.target.value).sourceTypes;
  }

  onSubmit(): void {
    if (!this.requestForm.valid) {
      this.requestForm.markAllAsTouched();
    } else {
      this.loading = true;
      const formData = new FormData();
      formData.append('name', this.requestForm.value.name);
      this.fileData.forEach((file: any, index: number) => {

        if (file) {
          let fileExtensionCorrect: boolean = true;
          if (!file.name) {
            this.errorMsg = this.translate.instant('FORM.FILE_INVALID', { index: index + 1 });
            fileExtensionCorrect = false;
            this.loading = false;
            return;
          }

          const parts = file.name.split('.');
          if (parts.length <= 1) {
            this.errorMsg = this.translate.instant('FORM.FILE_NO_EXTENSION', { index: index + 1 });
            fileExtensionCorrect = false;
            this.loading = false;

            return;
          }

          const extensionPart = parts.pop();
          if (extensionPart === undefined) {
            this.errorMsg = this.translate.instant('FORM.FILE_EXTENSION_UNKNOWN', { index: index + 1 });
            fileExtensionCorrect = false;
            this.loading = false;

            return;
          }

          const extension = extensionPart.toLowerCase();
          const allowedExtensions = ['xls', 'xlsx', 'csv', 'ods', 'txt', 'jrn', 'pdf'];
          if (!allowedExtensions.includes(extension)) {
            this.errorMsg = this.translate.instant('FORM.FILE_INVALID_EXTENSION', {
              index: index + 1,
              extensions: this.acceptedFileTypes[index + 1],
            });

            fileExtensionCorrect = false;
            this.loading = false;

            return;
          }
          if (fileExtensionCorrect) {
            this.errorMsg = '';
            formData.append(`source${index + 1}`, file);
            formData.append(`typeSource${index + 1}`, this.requestForm.value[`typeSource${index + 1}`]);
          }
        }
      });
      if (this.errorMsg === '') {
        this.http
          .post(this.endpointPrefix + '/reconciliations', formData)
          .subscribe(
            (res: any) => {
              this.errorMsg = '';
              this.loading = false;
              this.router.navigateByUrl('/backoffice/requestlist');
            },
            (err: any) => {
              this.errorMsg = err;
              this.loading = false;
            }
          );
      }
    }
  }

  handleFileInput(event: any, index: number): void {
    const file = event.target.files?.[0];
    if (file) {
      this.fileData[index] = file;
    }
  }

  get isAdmin() {
    const userDetails: any = JSON.parse(localStorage.getItem('currentUser') ?? '' );
    return userDetails.authorities.some((elt: string) => elt === 'ROLE_ADMIN');
  }

  setMasterSourceTypes(event:any) {
    const externalSourcetypeCode = event.target.value;
    this.selectedSourcetype = this.sourcetypes()
      .find( sourcetype => sourcetype.code == externalSourcetypeCode);

    this.updateAcceptedFileTypes(this.selectedSourcetype.sourceFormat, 0);
    this.updateAcceptedFileTypes(this.selectedSourcetype?.sourceTypeMaster?.sourceFormat, 1);

    this.requestForm.patchValue({
      typeSource2: this.selectedSourcetype?.sourceTypeMaster?.code
    });
    console.log(this.selectedSourcetype)

    console.log(this.requestForm.value)
  }

  updateAcceptedFileTypes(format: string, sourceIndex: number): void {
    this.acceptedFileTypes[sourceIndex] = this.formatToExtension[format] || '';
  }
}
