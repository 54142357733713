import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { CommonModule } from '@angular/common';
import { Result, ResultResponse } from '../../models';
import { FriendlyDatePipe } from '../../pipes/friendly-date.pipe';
import { ReadableStatusPipe } from '../../pipes/readable-status.pipe';
import { FormatAmountPipe } from '../../pipes/format-amount.pipe';
import { RuntimePipe } from '../../pipes/runtime.pipe';
import { RecapComponent } from './recap/recap.component';

import { ReactiveFormsModule } from '@angular/forms';
import {JubooResultComponent} from "juboo-result";
import { FilterableField } from 'juboo-result/lib/models/filterableField';

interface AdditionalInfo {
  label: string;
  show: boolean;
}

@Component({
  selector: 'app-request-detail',
  standalone: true,
  imports: [
    CommonModule,
    HttpClientModule,
    RouterModule,
    FriendlyDatePipe,
    ReadableStatusPipe,
    FormatAmountPipe,
    RuntimePipe,
    RecapComponent,
    ReactiveFormsModule,
    JubooResultComponent
  ],
  templateUrl: './request-detail.component.html',
  styleUrl: './request-detail.component.css',
  encapsulation: ViewEncapsulation.None,
})
export class RequestDetailComponent implements OnInit {
  private endpointPrefix = environment.apiUrl;
  loading: boolean = false;
  requestResult: ResultResponse;
  errorMsg: string = '';
  filteredResult: Result[] = [];
  additionalColumns1: AdditionalInfo[] = [];
  additionalColumns2: AdditionalInfo[] = [];
  colorPrimary = 'bg-primary';
  colorSecondary = 'bg-secondary-light';
  filterableFields1:  FilterableField[] = [];
  filterableFields2:  FilterableField[] = [];
  cursorRequests  : any [];
  basePath : string = "/backoffice/requestlist";
  requestDetailPath : string = "/backoffice/requestdetail";

  constructor(private route: ActivatedRoute, private http: HttpClient) {}

  ngAfterContentInit(){
    this.route.paramMap.subscribe((params: any) => {
      const requestId = Number(params.get('id'));
      this.loading = true;
      if (requestId) {
        this.http
          .get(this.endpointPrefix + '/reconciliations/reports/' + requestId)
          .subscribe({
            next: (res: any) => {
              this.requestResult = res;
              this.filteredResult = res.results;
              this.requestResult = res;
              this.filteredResult = res.results;
              const configSource1 = res?.reconciliation?.source1?.configuration;
              const configSource2 = res?.reconciliation?.source2?.configuration;
              if (configSource1) {
                const configSource1Obj = JSON.parse(configSource1);
                this.filterableFields1 = configSource1Obj?.filterableFields;
              }
              if (configSource2) {
              const configSource2Obj = JSON.parse(configSource2);
              this.filterableFields2 = configSource2Obj?.filterableFields;
              }
              this.getColumnsName(res.results);
              this.getListRequest(res?.reconciliation?.archived);

              res.reconciliation.errorKey
                ? (this.errorMsg = res.reconciliation.errorMessage)
                : (this.errorMsg = '');
            },
            error: (error: any) => {
              this.errorMsg = error;
            },
            complete: () => {
              this.loading = false;
            },
          });
      } else {
        this.errorMsg = "Aucun ID n'a été renseigné";
      }
    });
  }

  ngOnInit() {
    
  }

  getListRequest(value :boolean){
    this.http.get(
      this.endpointPrefix + '/reconciliations?archive=' + value).subscribe({
        next: (res: any) => {
          this.cursorRequests = res.map((item: any, index: any) => ({
            ...item,
            index,
          }));
        },
        error: (error: any) => {
          this.errorMsg = error;
        },
        complete: () => {
          this.loading = false;
        },

      });
  }


  getColumnsName(results: Result[]) {
    for (let elt of results) {
      if (elt.additionalInfos1) {
        Object.keys(elt.additionalInfos1).forEach((item) =>
          this.additionalColumns1.push({ label: item, show: false })
        );
        break;
      }
    }
    for (let elt of results) {
      if (elt.additionalInfos2) {
        Object.keys(elt.additionalInfos2).forEach((item) =>
          this.additionalColumns2.push({ label: item, show: false })
        );
        break;
      }
    }
  }

  download(e: any) {
    console.log(e);
    this.http
      .get(
        this.endpointPrefix +
          `/reconciliations/download/${e.reconciliation.id}?type=${e.source.code}`,
        { responseType: 'blob' }
      )
      .subscribe((data: Blob) => {
        const blob = new Blob([data], { type: 'application/octet-stream' });
        const url = window.URL.createObjectURL(blob);

        const link = document.createElement('a');

        link.href = url;
        link.download = <string>e.source.path;
        link.click();

        window.URL.revokeObjectURL(url);
      });
  }
}
