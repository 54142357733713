import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})

export class LanguageService {
    constructor(private translate: TranslateService) {}
  
    initializeTranslation(): void {
        const browserLang = this.getBrowserLanguage();
        const supportedLanguages = ['en', 'fr'];
        const defaultLang = 'fr';
        const langToSet = supportedLanguages.includes(browserLang)
        ? browserLang
        : defaultLang;

        console.log('browserLang:', browserLang);
        console.log('langToSet:', langToSet);

        this.translate.setDefaultLang(defaultLang);
        this.translate.use(langToSet);
    }
  
    private getBrowserLanguage(): string {
        const navigatorLang = navigator.language || (navigator as any).userLanguage;
        return navigatorLang ? navigatorLang.split('-')[0] : 'fr';
    }
  }
  