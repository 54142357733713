import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators, ReactiveFormsModule} from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { AuthService } from '../core/services/auth.service';
import { first } from 'rxjs';
import { LanguageService } from '../core/interceptor/language.service';
import { TranslateDirective, TranslatePipe, TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-forgottenpassword',
  standalone: true,
  imports: [RouterModule, ReactiveFormsModule, CommonModule, HttpClientModule, TranslatePipe, TranslateDirective],
  templateUrl: './forgottenpassword.component.html',
  styleUrl: './forgottenpassword.component.scss'
})
export class ForgottenpasswordComponent {
  forgotPasswordForm!: FormGroup;
  successMessage : any;
  error : any;
  showButtonConfirme : boolean = true;

  constructor(private fb: FormBuilder,private userService: AuthService, private router: Router, private translate: TranslateService, private languageService: LanguageService) {
    this.forgotPasswordForm = this.fb.group({
      username: ['', [Validators.required]],
    });
  }

  onSubmit(event: Event): void {
    event.preventDefault();
    if (this.forgotPasswordForm.valid) {
      const username = this.forgotPasswordForm.value.username;
      this.successMessage = null;
      this.error = null;
      this.userService.forgottentPassword(username)
      .pipe(first())
      .subscribe({
        next: response => {
          this.successMessage = this.translate.instant('FORGOT_PASSWORD.SUCCESS.EMAIL_SENT', { email: username });
          this.showButtonConfirme = false;
          setTimeout(() => {
            this.router.navigate(['/login']);
          }, 5000);
        },
        error: error => {
          switch (error.status) {
            case 0:
              this.error = this.translate.instant('FORGOT_PASSWORD.ERRORS.CONNECTION');
              break;

            case 408:
              this.error = this.translate.instant('FORGOT_PASSWORD.ERRORS.TIMEOUT');
              break;

            case 500:
              this.error = this.translate.instant('FORGOT_PASSWORD.ERRORS.SERVER');
              break;

            default:
              this.error = error.message ? error.message : error.slice(3, error.length);
              break;
          }
        }
      });
    }
  }
}
