
<div
  class="w-full flex min-h-[100vh] flex-col justify-center items-center bg-brown-500"
  id="login-bg">
  <div
    class="flex w-[350px] bg-white flex-col justify-center px-6 py-12 lg:px-8 rounded-xl border border-radius"
  >
    <div class="sm:mx-auto sm:w-full sm:max-w-sm">
      <div class="my-4 flex justify-center items-center">
        <img
          src="./../assets/logo.png"
          class="pl-0 pr-4"
          style="width: 80%"
          alt="Logo Juboo"
          loading="lazy"
        />
      </div>
      <h2 class="text-center">{{ 'RESET_PASSWORD.TITLE' | translate }}</h2>
    </div>

    <div class="sm:mx-auto sm:w-full sm:max-w-sm">
      <form
        class="space-y-6"
        [formGroup]="resetPasswordForm"
        (submit)="onSubmit($event)"
      >
        <div *ngIf="successMessage" class="bg-green-200 text-green-700 mt-3 p-5 justify-center items-center rounded mb-0">
            {{ successMessage }}
        </div>
        <div *ngIf="error" class="bg-red-200 text-red-700 mt-3 p-5 justify-center items-center rounded mb-0">
            {{ error }}
        </div>
        <div *ngIf="showButtonConfirme">
            <div>
                <label for="password1" class="label">{{ 'RESET_PASSWORD.LABELS.PASSWORD' | translate }}</label>
                <div class="mt-2">
                    <input
                    type="password"
                    id="password"
                    name="password"
                    class="input"
                    formControlName="password"
                    placeholder="********"
                    />
                </div>
            </div>
            <div class="mt-4">
                <label for="password2" class="label">{{ 'RESET_PASSWORD.LABELS.CONFIRM_PASSWORD' | translate }}</label>
                <div class="mt-2">
                    <input
                    type="password"
                    id="password2"
                    name="password2"
                    class="input"
                    formControlName="password2"
                    placeholder="********"
                    />
                </div>
            </div>
            <div class="w-full flex justify-center items-center mb-4 mt-4">
                <button class="button">{{ 'RESET_PASSWORD.BUTTONS.CONFIRM' | translate }}</button>
            </div>
        </div>
      </form>
    </div>
  </div>
</div>
