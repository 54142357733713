import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ReconciliationDTO } from '../../../models/reconciliation';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { ReadableStatusPipe } from '../../../pipes/readable-status.pipe';
import { FriendlyDatePipe } from '../../../pipes/friendly-date.pipe';
import { DateTooltipDirective } from '../../../directives/date-tooltip.directive';
import { RuntimePipe } from '../../../pipes/runtime.pipe';
import { Subscription, interval } from 'rxjs';
import { RequestService } from '../../../backoffice/request-service/request.service';
import { RoleService } from '../../services/role.service';
import {FriendlyNamePipe} from "../../../pipes/friendly-name.pipe";
import { RequestStatusDirective } from '../../../directives/requeststatus/request-status.directive';

@Component({
  selector: 'app-request-li',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    ReadableStatusPipe,
    FriendlyDatePipe,
    DateTooltipDirective,
    RuntimePipe,
    FriendlyNamePipe,
    RequestStatusDirective,
  ],
  templateUrl: './request-li.component.html',
  styleUrl: './request-li.component.css',
})
export class RequestLiComponent {
  private subscription: Subscription;
  isAgent: boolean = false;
  @Output() requestArchivedId = new EventEmitter<number>();
  @Output() message = new EventEmitter<string>();
  @Input() request: ReconciliationDTO;

  constructor(private requestService: RequestService, private roleService: RoleService) { }
  ngOnInit(): void {
    this.isAgent = this.roleService.isAgent();
    if (!(this.request.status === 'DONE' || this.request.status === 'RECONCILIATION_ERROR' || this.request.status === "TRANSFORMATION_ERROR")) {
      this.subscription = interval(5000).subscribe(() => {
        this.checkStatus();
      });
    }
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  checkStatus(): void {
    this.requestService.getRequest(this.request.id).subscribe((res: any) => {
      if (res.status === 'DONE' || res.status === 'RECONCILIATION_ERROR' || res.status === "TRANSFORMATION_ERROR") {
        this.request = res
        this.subscription.unsubscribe();
      }
    });
  }

  getProgressStatus(reconciliation: ReconciliationDTO) {
    return this.onLoader.includes(reconciliation.status);
  }

  archiveRequest(id: number, event: Event) {
    event.stopPropagation();
    this.requestService.archiveRequest(id).subscribe(
      (res: any) => {
        this.requestArchivedId.emit(this.request.id)
      },
      (err: any) => {
        this.message.emit(err.error.text)
      })
  }
  get onLoader() {
    return ['ON_TRANSFORMATION', 'TO_RECONCILE', 'ON_RECONCILIATION'];
  }
}
