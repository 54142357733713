import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, ValidationErrors, Validators } from '@angular/forms';
import { AuthService } from '../core/services/auth.service';
import { first } from 'rxjs';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { TranslateDirective, TranslatePipe, TranslateService } from '@ngx-translate/core';
import { LanguageService } from '../core/interceptor/language.service';

@Component({
  selector: 'app-reset-password',
  standalone: true,
  imports: [ReactiveFormsModule, CommonModule, RouterModule, TranslatePipe, TranslateDirective],
  templateUrl: './reset-password.component.html',
  styleUrl: './reset-password.component.scss'
})
export class ResetPasswordComponent implements OnInit{
  resetPasswordForm!: FormGroup;
  successMessage : any;
  error : any;
  key: any;
  showButtonConfirme : boolean = true;

  constructor(private fb: FormBuilder,private userService: AuthService,private activatedRoute: ActivatedRoute, private router: Router, private translate: TranslateService, private languageService: LanguageService) {
    this.resetPasswordForm = this.fb.group({
      password: ['', [Validators.required]],
      password2: ['', [Validators.required]],
    });
  }

  ngOnInit(){
    this.activatedRoute.queryParamMap.subscribe((params) => {
      this.key = params.get("key");
    });
    this.languageService.initializeTranslation();
  }

  onSubmit(event: Event): void {
    event.preventDefault();
    if (this.resetPasswordForm.valid) {
      const password = this.resetPasswordForm.value.password;
      const password2 = this.resetPasswordForm.value.password2;
      this.successMessage = null;
      this.error = null;
      if (password !== password2) {
        this.error = this.translate.instant('RESET_PASSWORD.ERRORS.INCORRECT_PASSWORD');
        return;
      }

      const strengthError = this.checkPasswordStrength(password);
      if (strengthError) {
        this.error = strengthError;
        return;
      }
      this.userService.resetForgottentPassword({'key': this.key,'newPassword': password})
      .pipe(first())
      .subscribe({
        next: response => {
          this.successMessage = this.translate.instant('RESET_PASSWORD.SUCCESS.PASSWORD_RESET');
          this.showButtonConfirme = false;
          setTimeout(() => {
            this.router.navigate(['/login']);
          }, 3000);
        },
        error: error => {
          switch (error.status) {
            case 0:
              this.error = this.translate.instant('RESET_PASSWORD.ERRORS_STATUS.CONNECTION_ERROR');
              break;

            case 408:
              this.error = this.translate.instant('RESET_PASSWORD.ERRORS_STATUS.TIMEOUT');
              break;

            case 500:
              this.error = this.translate.instant('RESET_PASSWORD.ERRORS_STATUS.SERVER_ERROR');
              break;

            default:
              this.error = error.message ? error.message : error.slice(3, error.length);
              break;
          }
        }
      });
    }
  }

  checkPasswordStrength(password: string): string | null {
    const minLength = 10;
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);

    if (password.length < minLength) {
      return this.translate.instant('RESET_PASSWORD.ERRORS.INCORRECT_PASSWORD');
    }
    if (!hasUpperCase) {
      return this.translate.instant('RESET_PASSWORD.ERRORS.INCORRECT_PASSWORD');
    }
    if (!hasLowerCase) {
      return this.translate.instant('RESET_PASSWORD.ERRORS.INCORRECT_PASSWORD');
    }
    if (!hasSpecialChar) {
      return this.translate.instant('RESET_PASSWORD.ERRORS.INCORRECT_PASSWORD');
    }

    return null;
  }

}
