<juboo-result
  [requestResult]="requestResult"
  [additionalColumns1]="additionalColumns1"
  [additionalColumns2]="additionalColumns2"
  [filteredResult]="filteredResult"
  [loading]="loading"
  [back]="true"
  (downloadEvent)="download($event)"
  [filterableFields1]="filterableFields1"
  [filterableFields2]="filterableFields2"
  [cursorRequests]="cursorRequests"
  [basePath]="basePath"
  [requestDetailPath]="requestDetailPath"
>
</juboo-result>
